<template>
  <b-table-filter
    title="Danh Sách Trạng Thái"
    :items="rows"
    :fields="fields"
    :isBusy="busy"
  >
    <template #rightAction>
      <b-button :to="{name: 'orders-status-create'}" size="sm" variant="success"><feather-icon icon="PlusIcon" />Thêm Trạng Thái</b-button>
    </template>
  </b-table-filter>
</template>

<script>
import { BButton, BAlert, BRow, BCol, BCardSubTitle, BCardHeader, BFormInput,
} from 'bootstrap-vue'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'


export default {
  components: {
    BCardHeader,
    BAlert,
    BFormInput,
    BRow,
    BCol,
    BCardSubTitle,
    BButton,
    BTableFilter,
    FeatherIcon
},
  data() {
    return {
      busy: false,
      userStatus: [],
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'Tên trạng thái',
          key: 'name',
        },
        {
          label: 'Không tiếp cận',
          key: 'approach',
        },
        {
          label: 'Không tính doanh thu',
          key: 'no_sale',
        },
        {
          label: 'Vị trí',
          key: 'location',
        },
        {
          label: 'Level',
          key: 'level',
        },
        {
          label: 'Color',
          key: 'color',
        },
        {
          label: 'Tác vụ',
          key: 'act',
        },
      ],
      rows: [],
      rowsUserCreate: [],
      searchTermOrder: '',
    }
  },
  created() {
    this.rows = [
      { name: 'Khai thác lại', no_sale: 'Có', approach: 'Có', location: 1, level: 1, color: '#ff0000', status: false },
      { name: 'Chưa xác nhận', no_sale: 'Có', approach: 'Có', location: 2, level: 1, color: '#ff0000', status: false },
      { name: 'Hủy', approach: 'Có', no_sale: 'Có', location: 4, color: '#ff0000', level: 1, status: false },
      { name: 'Gọi máy bận', approach: 'Có', no_sale: 'Có', location: 3, level: 1, color: '#ff0000', status: false },
      { name: 'Không nghe điện', approach: 'Có', no_sale: 'Có', location: 5, level: 1, color: '#ff0000', status: false },
      { name: 'Kế toán mặc định', approach: 'Có', no_sale: 'Có', location: 6, level: 1, color: '#ff0000', status: false },
      { name: 'Xác Nhận - Chốt đơn', approach: 'Có', no_sale: 'Có', location: 7, level: 1, color: '#ff0000', status: false },
      { name: 'Chuyển hàng', approach: 'Có', no_sale: 'Có', location: 8, level: 1, color: '#ff0000', status: false },
      { name: 'Chuyển hoàn', approach: 'Có', no_sale: 'Có', location: 9, level: 1, color: '#ff0000', status: false },
      { name: 'Đã thu tiền', approach: 'Có', no_sale: 'Có', location: 10, level: 1, color: '#ff0000', status: false },
      { name: 'Đã trả hàng về kho', approach: 'Có', level: 1, no_sale: 'Có', location: 11, color: '#ff0000', status: false },
      { name: 'Thành công', approach: 'Có', no_sale: 'Có', location: 12, level: 1, color: '#ff0000', status: false },
      
    ]
  },
  methods: {
  },
}
</script>

<style lang="scss" >
// HTML
.card {
  .card-header .heading-elements {
    position: static;
    background: red;
  }
}
.color-picker > input{
  width: 77%;
  border: 1px solid #f0e2e2;
  height: 40px;
}
span[class^="edit-name-"] {
  margin-top: 10px;
}
.alert-msg {
  margin-top: 1em;
  padding: 1rem;
}
</style>